import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const Timeout = ({ open = true, onClose = true, onRetry }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="timeout-dialog-title"
            aria-describedby="timeout-dialog-description"
        >
            <DialogTitle id="timeout-dialog-title">Request Timed Out</DialogTitle>
            <DialogContent>
                <DialogContentText id="timeout-dialog-description">
                    The request is taking longer than expected. Please try again or refresh the page.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                onClick={onRetry}
                sx ={{
                    backgroundColor:"black",
                    color:"white",
                    '&:hover': {
                        backgroundColor: 'black', // Keeps the same color on hover
                    },
                    '&:focus': {
                         backgroundColor: 'black', // Keeps the same color on focus
                     },
                    '&:active': {
                        backgroundColor: 'black', // Keeps the same color when active
                    }
                 }}
                >
                    Retry
                </Button>
                <Button 
                onClick={onClose} 
                sx ={{
                    backgroundColor:"black",
                    color:"white",
                    '&:hover': {
                        backgroundColor: 'black', // Keeps the same color on hover
                    },
                    '&:focus': {
                         backgroundColor: 'black', // Keeps the same color on focus
                     },
                    '&:active': {
                        backgroundColor: 'black', // Keeps the same color when active
                    }
                 }}
                 variant='contained'
                autoFocus
                >
                    Refresh Page
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Timeout;

