import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { Collapse, Tooltip, Popover, Drawer, Menu, MenuItem, ListSubheader } from "@mui/material";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate} from "react-router-dom";
const drawerWidth = 240;

const submenusConfig = {
  reports: ["/internal", "/standards", "/csrd", "/GenerateReport"],
  company: [
    "/map",
    "/listofentities",
    "/listofbusinessunits",
    "/listsitelocations",
    "/Businessunit",
    "/company",
    "/sitelocation",
  ],
  settings: ["/preferences", "/listofusers"],
  // Add other submenu paths as needed
};

const includesAnyPath = (pathname, paths) => paths.some((path) => pathname.includes(path));

export default function SideNavigation(props) {
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [submenus, setSubmenus] = useState({
    reports: false,
    company: false,
    settings: false,
    // Add more submenu keys as needed for future expansion
  });
  const [anchorEl, setAnchorEl] = useState({
    reports: null,
    company: null,
    settings: null,
  });
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  function handleItemClick() {
    setOpen1(!open1);
  }

  const handleToggleSidebar = () => {
    setOpen(!open);
    setIsCollapsed(!isCollapsed);
    setSubmenus((prev) => ({
      ...prev,
      reports: false,
      company: false,
      settings: false,
    }));
    props.getOpen(!open);
  };

  const handleSubMenuClick = (submenuKey) => {
    if (open) {
      setSubmenus((prev) => ({
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = key === submenuKey ? !prev[key] : false;
          return acc;
        }, {}),
      }));
    }
  };

  const determineActiveSubmenu = (submenuKey) => {
    return submenusConfig[submenuKey].some((path) => location.pathname.includes(path));
  };

  const handleMenuClick = (event, popoverName) => {
    setPopoverAnchor(popoverName === popoverAnchor ? null : popoverName);
  };

  const handleMenuClose = () => {
    setPopoverAnchor(null);
  };

  useEffect(() => {
    setSubmenus((prev) => ({
      ...prev,
      reports: determineActiveSubmenu("reports"),
      company: determineActiveSubmenu("company"),
      settings: determineActiveSubmenu("settings"),
    }));

    if (open) {
      setSubmenus((prev) => ({
        ...prev,
        reports: determineActiveSubmenu("reports"),
        company: determineActiveSubmenu("company"),
        settings: determineActiveSubmenu("settings"),
      }));
    } else {
      setSubmenus((prev) => ({
        ...prev,
        reports: false,
        company: false,
        settings: false,
      }));
    }
  }, [location.pathname, open]);
  let navigate = useNavigate ();
  useEffect(() => {
    const username = localStorage.getItem('userName');
    //console.log(username);
    if(username === '' || username === null || username === 'undefined'){
        navigate("/");
    }
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Logo */}
        {open && (
          <div>
            <a href="/home">
              <img src="assets/img/logo.png" alt="ecoPrism" className="logo-sidebar" />
            </a>
          </div>
        )}

        {/* Collapse icon */}
        <IconButton
          color="inherit"
          aria-label="toggle drawer"
          onClick={handleToggleSidebar}
          edge="end"
        >
          {open ? (
            <ChevronLeftIcon
              style={{ color: "#407775", backgroundColor: "#EEF1F5", borderRadius: "9px 0 0 9px" }}
            />
          ) : (
            <MenuIcon style={{ color: "aliceblue", marginLeft: "10px" }} />
          )}
        </IconButton>
      </div>

      <List>
        {/* Dashboard */}
        <ListItem
          onClick={handleItemClick}
          component={Link}
          to="/dashboard"
          className={`${
            location.pathname === "/dashboard"
              ? open
                ? "icon-list-active"
                : "icon-list-collapse-active"
              : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                location.pathname === "/dashboard"
                  ? "assets/img/sidebar/active/Dashboard.png"
                  : "assets/img/sidebar/Dashboard.png"
              }
              alt="Dashboard"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        {/* Reports */}
        <ListItem
          onClick={
            isCollapsed
              ? (event) => handleMenuClick(event, "reports")
              : () => handleSubMenuClick("reports")
          }
          className={`${
            includesAnyPath(location.pathname, submenusConfig.reports) &&
            (submenus.reports || includesAnyPath(location.pathname, submenusConfig.reports))
              ? open
                ? "main-menu-item-active"
                : "icon-list-collapse-active"
              : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                includesAnyPath(location.pathname, submenusConfig.reports) &&
                (submenus.reports || includesAnyPath(location.pathname, submenusConfig.reports))
                  ? "assets/img/sidebar/active/Reports.png"
                  : "assets/img/sidebar/Reports.png"
              }
              alt="Reports"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {submenus.reports ? (
            <IconExpandLess style={{ color: "#fab430" }} />
          ) : (
            <IconExpandMore style={{ color: "#fab430" }} />
          )}
        </ListItem>
        {/* Reports Popover */}
        <Popover
          open={Boolean(popoverAnchor === "reports")}
          anchorEl={anchorEl.reports}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 95, left: 60 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List>
            <ListSubheader>Reports</ListSubheader>
            <ListItem className="sub-menu-close" button component={Link} to="/standards" onClick={handleMenuClose}>
              Standards & Frameworks
            </ListItem>
          </List>
        </Popover>
        {/* Reports Submenu */}
        <Collapse in={submenus.reports} timeout="auto" unmountOnExit>
          <List component="div" class="submenu submenu-list">
            <ListItem
              button
              className={
                location.pathname === "/standards" ||
                location.pathname === "/csrd" ||
                location.pathname === "/GenerateReport"
                  ? "icon-list-active-submenu submenu-item"
                  : "submenu-item main-menu-item"
              }
              component={Link}
              to="/standards"
            >
              <ListItemText className="pd-0" inset primary="Standards & Frameworks" />
            </ListItem>
          </List>
        </Collapse>
        {/* Green Button */}
        <ListItem
          onClick={handleItemClick}
          component={Link}
          to="/comingsoon"
          className={`${
            location.pathname === "/comingsoon"
              ? open
                ? "icon-list-active"
                : "icon-list-collapse-active"
                : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                location.pathname === "/comingsoon"
                  ? "assets/img/sidebar/active/Greenbutton.png"
                  : "assets/img/sidebar/Green.png"
              }
              alt="Green Button"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText className="green-btn-list-item" primary="Green Button" />
        </ListItem>
        {/* Insights */}
        <ListItem
          onClick={handleItemClick}
          component={Link}
          to="/insights"
          className={`${
            location.pathname === "/insights" ||
            location.pathname === "/assessing-low-re" ||
            location.pathname === "/electricity-cost-evaluation" ||
            location.pathname === "/assessing-elec-usage"
              ? open
                ? "icon-list-active"
                : "icon-list-collapse-active"
              : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                location.pathname === "/insights"
                  ? "assets/img/sidebar/active/Insights.png"
                  : "assets/img/sidebar/Insights.png"
              }
              alt="Insights"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Insights" />
        </ListItem>
        {/* Configuration */}
        <ListItem
          onClick={handleItemClick}
          component={Link}
          to="/allconfiguration"
          className={`${
            location.pathname === "/allconfiguration"
              ? open
                ? "icon-list-active"
                : "icon-list-collapse-active"
                : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                location.pathname === "/allconfiguration"
                  ? "assets/img/sidebar/active/Configuration.png"
                  : "assets/img/sidebar/Configuration.png"
              }
              alt="Configuration"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Configuration" />
        </ListItem>
        {/* Company */}
        <ListItem
          onClick={
            isCollapsed
              ? (event) => handleMenuClick(event, "company")
              : () => handleSubMenuClick("company")
          }
          className={`${
            includesAnyPath(location.pathname, submenusConfig.company) &&
            (submenus.company || includesAnyPath(location.pathname, submenusConfig.company))
              ? open
                ? "main-menu-item-active "
                : "icon-list-collapse-active"
                : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                includesAnyPath(location.pathname, submenusConfig.company) &&
                (submenus.company || includesAnyPath(location.pathname, submenusConfig.company))
                  ? "assets/img/sidebar/active/Company.png"
                  : "assets/img/sidebar/Company.png"
              }
              alt="Company"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Company" />
          {submenus.company ? (
            <IconExpandLess style={{ color: "#fab430" }} />
          ) : (
            <IconExpandMore style={{ color: "#fab430" }} />
          )}
        </ListItem>
        {/* Company Popover */}
        <Popover
          open={Boolean(popoverAnchor === "company")}
          anchorEl={anchorEl.company}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 288, left: 60 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List>
            <ListSubheader>Company</ListSubheader>
            <ListItem button className="sub-menu-close" component={Link} to="/listofentities" onClick={handleMenuClose}>
              Legal Entities
            </ListItem>
            <ListItem button className="sub-menu-close" component={Link} to="/listofbusinessunits" onClick={handleMenuClose}>
              Business Units
            </ListItem>
            <ListItem button className="sub-menu-close" component={Link} to="/listsitelocations" onClick={handleMenuClose}>
              Site Locations
            </ListItem>
          </List>
        </Popover>
        {/* Company Submenu */}
        <Collapse in={submenus.company} timeout="auto" unmountOnExit>
          <List component="div" class="submenu submenu-list">
            <ListItem
              button
              className={
                location.pathname === "/listofentities" || location.pathname === "/company"
                  ? "icon-list-active-submenu submenu-item"
                  : "submenu-item main-menu-item"
              }
              component={Link}
              to="/listofentities"
            >
              <ListItemText className="pd-0" inset primary="Legal Entities" />
            </ListItem>
            <ListItem
              button
              className={
                location.pathname === "/listofbusinessunits" ||
                location.pathname === "/Businessunit"
                  ? "icon-list-active-submenu submenu-item"
                  : "submenu-item main-menu-item"
              }
              component={Link}
              to="/listofbusinessunits"
            >
              <ListItemText className="pd-0" inset primary="Business Units" />
            </ListItem>
            <ListItem
              button
              className={
                location.pathname === "/listsitelocations" || location.pathname === "/sitelocation"
                  ? "icon-list-active-submenu submenu-item"
                  : "submenu-item main-menu-item"
              }
              component={Link}
              to="/listsitelocations"
            >
              <ListItemText className="pd-0" inset primary="Site Locations" />
            </ListItem>
          </List>
        </Collapse>
        {/* Settings */}
        <ListItem
          onClick={
            isCollapsed
              ? (event) => handleMenuClick(event, "settings")
              : () => handleSubMenuClick("settings")
          }
          className={`${
            includesAnyPath(location.pathname, submenusConfig.settings) &&
            (submenus.settings || includesAnyPath(location.pathname, submenusConfig.settings))
              ? open
                ? "main-menu-item-active"
                : "icon-list-collapse-active"
              : "main-menu-item"
          }`}
          button
        >
          <ListItemIcon className={open ? "list-icon" : ""}>
            <img
              src={
                includesAnyPath(location.pathname, submenusConfig.settings) &&
                (submenus.settings || includesAnyPath(location.pathname, submenusConfig.settings))
                  ? "assets/img/sidebar/active/Settings.png"
                  : "assets/img/sidebar/Settings.png"
              }
              alt="Settings"
              className="sidebar-image"
            />
          </ListItemIcon>
          <ListItemText primary="Settings" />
          {submenus.settings ? (
            <IconExpandLess style={{ color: "#fab430" }} />
          ) : (
            <IconExpandMore style={{ color: "#fab430" }} />
          )}
        </ListItem>
        {/* Settings Popover */}
        <Popover
          open={Boolean(popoverAnchor === "settings")}
          anchorEl={anchorEl.settings}
          onClose={handleMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 335, left: 60 }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List>
            <ListSubheader>Settings</ListSubheader>
            <ListItem
  button
  component={Link}
  to="/listofusers"
  onClick={handleMenuClose}
  className="sub-menu-close" // Add your desired class here
>
  User Management
</ListItem>
          </List>
        </Popover>
        {/* Settings Submenu */}
        <Collapse in={submenus.settings} timeout="auto" unmountOnExit>
          <List component="div" class="submenu submenu-list">
            <ListItem
              button
              className={
                location.pathname === "/listofusers"
                  ? "icon-list-active-submenu submenu-item"
                  : "submenu-item main-menu-item active"
              }
              component={Link}
              to="/listofusers"
            >
              <ListItemText className="pd-0" inset primary="User Management" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );
}
