import axios from "axios";

const PostWithTimeout = (url,data, options, timeout = 5000) => {
    return Promise.race([
        axios.post(url, data, options),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), timeout)
        )
      ]);
};

export default PostWithTimeout;