import {MenuOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {Drawer, Menu, Flex, Collapse, Button } from "antd";
import { useState } from "react";
import { Link } from 'react-router-dom';
import homeIcon from '../Grey Icons/Home.png';
import appsIcon from '../Grey Icons/Apps.png';
import { SettingOutlined, GithubOutlined, BulbOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

const HamburgerMenu = () => {
    const [drawer, setDrawer]  = useState(false);
    const navigate = useNavigate();
  



  
    const integerationOptions = [
      {
      label: "Github",
      value: "github",
      component:<GithubOutlined/>
    },
    {
      label: "Storage",
      value: "blob",
      component:<BulbOutlined/>

    },
    {
      label: "SharePoint",
      value: "share",
      component:<SettingOutlined/>
    }
  ]
    const handleChatClick = () => {
      navigate("/ChatPage?indexId=hr-policy");
    };

    
  const handleChange = (event) => {
    console.log("event", event)
    if(event === "share") {
      navigate("/project");
      }
   };

  return (
    <div>
      <MenuOutlined
        style={{
          fontSize: "1.5rem",
          color: "##215dec",
        }}
        onClick={setDrawer}
      />
      <Drawer
      width={240}
      headerStyle={
        {
          marginLeft: "150px"
        }
      }
        placement={"left"}
        closable={true}
        onClose={() => setDrawer(false)}
        open={drawer}>
        <Menu
          theme={"light"}
          defaultOpenKeys={["archive"]}
          mode="inline"
          className="menu-items gap-2"
          style={{
            borderRight: "none",
          }}>
            <Menu.Item>
                <ul className="navbar-nav mb-2 mb-lg-0 user-box ml-2">
                <li>
                  <Link to="/"> 
          <div className="menu-item">
            <div className="icon">
              <img src={homeIcon} alt="Chat" className="black-icon" />
              <span className="home-text">Home</span>
              </div>
          </div>
          </Link>
        </li>
        </ul>
            </Menu.Item>
            <Menu.Item>
            <ul className="navbar-nav mb-2 mb-lg-0 user-box ml-2">
                <li onClick={handleChatClick}>
          <div className="menu-item">
            <div className="icon">
              <img src={appsIcon} alt="Chat" className="black-icon" />
              <span className="home-text">Chat</span>
              </div>
          </div>
        </li>
        </ul>
            </Menu.Item>
              <Collapse defaultActiveKey={['1']} ghost 
               onChange={() => ""}
               style={{
                // background: "#ffe58f", 
                width: "80%",
                marginLeft:"30px"
              }
              }
               expandIconPosition="start"
              >
           <Panel header="Integration">
              {

                integerationOptions.map((item)=>
                    <Flex wrap gap="small">
                        <Button 
                        key={item.value}
                        onClick={()=>handleChange(item.value)}
                        value={item.label}
                        type = "text"
                        style={{
                        borderColor:"white",
                        backgroundColor:"white",
                        color:"black"}}>
                        {item.component}
                        <span>{item.label}</span>  
                        </Button>
                    </Flex>
                )
              }
            </Panel>   
              </Collapse>
  
             
              {/* <collapse bordered={true} defaultActiveKey={['1']}  item={items}/> */}
            {/* </Menu.Item> */}
            
        </Menu>
      </Drawer>
    </div>
  );
};

export default HamburgerMenu;
