import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import LoginHeader from '../../components/LoginHeader';
import Navigation from '../../Helper/Navigation';
import './DocumentUpload.css';
import { useNavigate } from 'react-router-dom';
import ProjectView from '../Project/ProjectView';
import FileListPopup from '../../Helper/Components/FileListPopup';
import chatIcon from '../../assets/img/ChatIcon.png'; 
import filesIcon from '../../assets/img/FilesIcon.png'; 
import PlusIcon from '../../assets/img/plus.svg'; 
import ErrorComponent from '../../Helper/Components/ErrorComponent';
import Loader from '../../Loader'; // Import the Loader component

function Home() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [projectTitle, setProjectTitle] = useState("Create Project");
  const [showDropdown, setShowDropdown] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [projectDetails, setProjectDetails] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [allProjects, setAllProjects] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showMenuIndex, setShowMenuIndex] = useState(null);
  const [showProjectView, setShowProjectView] = useState(false);
  const [viewProjectId, setViewProjectId] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [showFilePopup, setShowFilePopup] = useState(false);
  const [popupProjectId, setPopupProjectId] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loader
  const [error, setError] = useState(null);

  const projectsPerPage = 3;

  const connectionString = process.env.REACT_APP_CONNECTION_STRING;

  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles.length > 0) {
      setFiles([...files, ...uploadedFiles]);
      setFilesUploaded(true);
      console.log("Files selected:", uploadedFiles);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
    handleFileUpload(event);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = () => {
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const renderFileList = () => {
    return Array.from(files).map((file, index) => (
      <div key={index} className="file-container">
        <div className="file-info">
          <i className="fa fa-file file-icon"></i>
          <a href={URL.createObjectURL(file)} download={file.name} className="file-name">{file.name}</a>
        </div>
        <i className="fa fa-check check-icon"></i>
      </div>
    ));
  };

  const handleTitleChange = (event) => {
    setProjectTitle(event.target.value);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const getRandomColor = () => {
    const colors = ['#FF5733', '#33FF57', '#3357FF', '#F33FF5', '#F5A733', '#33FFF3'];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const handleMenuToggle = (index) => {
    setShowMenuIndex(showMenuIndex === index ? null : index);
  };

  const handleViewClick = (indexId) => {
    setShowProjectView(true);
    setViewProjectId(indexId);
  };

  const handleEditClick = () => {
    console.log("Edit clicked");
  };

  const handleDeleteClick = () => {
    console.log("Delete clicked");
  };

  const handleNextClick = async () => {
    console.log("handleNextClick called");
    if (isSubmitting || !isProjectNameValid()) {
      console.log("Already submitting or project name invalid, skipping...");
      return;
    }
    setIsSubmitting(true);
  
    const description = document.getElementById('description').value;
  
    const projectData = {
      ProjectName: projectName,
      DocumentSource: selectedOptions.join(', '),
      Description: description,
      Tags: 'HR',
      CreatedBy: 'Admin',
      IsActive: true ,
      PartitionKey: 'Project'
    };
  
    console.log("Project Data:", projectData);
  
    try {
      const response = await fetch('https://acumantaiapi.azure-api.net/codecraftapi/create-project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(projectData)
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log("Project created:", result);
  
        const containerName = result.response.IndexId;
        setProjectDetails(result.response);
  
        setUploadStatus('success');
        console.log("Project data saved successfully");
  
        setNextClicked(true);
      } else {
        const errorData = await response.json();
        console.error("Error creating project:", response.statusText, errorData);
        setUploadStatus('failure');
        setError(response);
      }
  
    } catch (error) {
      console.error('Error creating project:', error);
      setUploadStatus('failure');
      setError(error);
    }
  
    setIsSubmitting(false);
  };

  const handleTrainClick = async () => {
    console.log("handleTrainClick called");
    if (isSubmitting) {
      console.log("Already submitting, skipping...");
      return;
    }
    setIsSubmitting(true);
    setLoading(true); // Show loader

    try {
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(projectDetails.IndexId);
      await containerClient.createIfNotExists();
      console.log("Container created:", projectDetails.IndexId);

      const uploadPromises = Array.from(files).map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async (event) => {
            const arrayBuffer = event.target.result;
            const blockBlobClient = containerClient.getBlockBlobClient(file.name);
            try {
              await blockBlobClient.uploadData(arrayBuffer);
              console.log("File uploaded:", file.name);
              resolve();
            } catch (error) {
              console.error("Error uploading file:", file.name, error);
              reject(error);
            }
          };
          reader.onerror = (error) => {
            console.error("Error reading file:", file.name, error);
            reject(error);
          };
          reader.readAsArrayBuffer(file);
        });
      });

      await Promise.all(uploadPromises);
      setUploadStatus('success');

      const response = await fetch(`https://acumantaiapi.azure-api.net/codecraftapi/pinecone/train-pinecone-from-blob-storage-for-project?container_name=${projectDetails.IndexId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        console.log("Training started successfully");
        const responseData = await response.json();
        console.log("Response data:", responseData);

        // Additional API call to create-docs-metadata
        const metadataPromises = Array.from(files).map(file => {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop();
          const metadata = {
            ProjectId: projectDetails.ProjectId,
            DocumentName: fileName,
            DocumentType: fileExtension,
            DocumentLocation: selectedOptions.join(', '),
            IsActive: true,
            CreatedBy: 'Admin'
          };

          return fetch('https://acumantaiapi.azure-api.net/codecraftapi/create-docs-metadata', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(metadata)
          });
        });

        await Promise.all(metadataPromises);
        console.log("Metadata created successfully");

        navigate(`/ChatPage?indexId=${projectDetails.IndexId}`);
      } else {
        const errorData = await response.json();
        console.error("Error starting training:", response.statusText, errorData);
        setError(response);
      }

    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:', error);
      setUploadStatus('failure');
      setError(error);
    }

    setIsSubmitting(false);
    setLoading(false); // Hide loader
  };

  const fetchAllProjects = async () => {
    try {
      const response = await fetch('https://acumantaiapi.azure-api.net/codecraftapi/get-projects', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const projects = await response.json();
        const projectsWithFileCount = await Promise.all(
          projects.results.map(async (project) => {
            const fileCount = await fetchFileCountFromContainer(project.IndexId);
            return { ...project, fileCount };
          })
        );
        setAllProjects(projectsWithFileCount);
        console.log("Fetched projects:", projectsWithFileCount);
      } else {
        const errorData = await response.json();
        console.error("Error fetching projects:", response.statusText, errorData);
        setFetchError('Error fetching projects');
        setError(errorData);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setFetchError('Error fetching projects');
      setError(error)
    }
  };

  const fetchFileCountFromContainer = async (indexId) => {
    try {
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(indexId);

      let fileCount = 0;
      for await (const response of containerClient.listBlobsFlat()) {
        fileCount++;
      }

      return fileCount;
    } catch (error) {
      console.error('Error fetching file count from Azure Blob Storage:', error);
      setError(error);
      return 0; // Return 0 if there's an error
    }
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  const handlePageClick = (pageIndex) => {
    setCurrentIndex(pageIndex * projectsPerPage);
  };

  const handleChatClick = (indexId) => {
    navigate(`/ChatPage?indexId=${indexId}`);
  };

  const handleFilesClick = (indexId) => {
    setPopupProjectId(indexId);
    setShowFilePopup(true);
  };

  const userEmail = localStorage.getItem('email');

  const allowedEmails = [
    'swapnil.agrawal@acumant.com',
    'manju.siddoutam@acumant.com',
    'naga@acumant.com',
    'sudiendra.siddautam@acumant.com',
    'deepak.gupta@acumant.com',
    'gaurav.dutta@acumant.com',
    'manish@acumant.com'
  ];

  const isProjectNameValid = () => {
    return projectName.length > 0 && projectName.length <= 16;
  };

  if(error) {
    return <ErrorComponent/>
  }

  return (
    <div className="page-container">
      <LoginHeader heading="Document Upload" className="header" />
      {/* <Navigation /> */}
      {loading && <Loader />} {/* Show loader when loading */}
      <div className={`main-content ${loading ? 'blur' : ''}`}> {/* Add blur class when loading */}
        <div className="container">
          {showCreateProject ? (
            <>
              <div className="header-content">
                <h1>{projectTitle}</h1>
                <p>Here you can create a new project</p>
              </div>
              <div className="content-container">
                <div className="content">
                  <div className="form-group">
                    <label htmlFor="project-name">Project Name <span style={{color: 'red'}}>*</span></label>
                    <input
                      type="text"
                      id="project-name"
                      placeholder="Enter Project Name"
                      maxLength="16"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      style={{ borderColor: !isProjectNameValid() && 'red' }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="project-name">Document Source</label>
                    <div className="custom-select" id="custom-select" onClick={toggleDropdown} tabIndex="0" onBlur={closeDropdown}>
                      {selectedOptions.length ? selectedOptions.join(', ') : 'Select a document source'}
                      {showDropdown && (
                        <div className="select-items" id="select-items">
                          <div className="select-item" onClick={() => setSelectedOptions(['File Uploader'])}>
                            File Uploader
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea name="description" id="description" placeholder="Enter Description"></textarea>
                  </div>
                </div>
                {nextClicked && (
                  <div className="files-uploaded">
                    <input type="file"
                      className={`upload-box ${dragging ? 'dragging' : ''}`}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onChange={handleFileUpload}
                      multiple
                      style={{ marginTop: '20px', marginBottom: '20px' }}
                    />
                    <div>Upload or drag and drop the files</div>
                    {filesUploaded && (
                      <div>
                        <br />
                        <div id="file-list">
                          {renderFileList()}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="buttons" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                <button
                  type="button"
                  className="custom-button"
                  style={{
                    width: '123px',
                    height: '53px',
                    background: '#FFFFFF',
                    border: '1px solid #407775',
                    opacity: 1
                  }}
                  disabled={isSubmitting}
                  onClick={() => setShowCreateProject(false)}
                >
                  Cancel
                </button>

                {!nextClicked ? (
                  <button
                    type="button"
                    className="next-button"
                    onClick={handleNextClick}
                    disabled={isSubmitting || !isProjectNameValid()}
                    style={{
                      width: '123px',
                      height: '53px',
                      background: '#0A80A0',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      fontSize: '20px',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      color: '#FFFFFF',
                      marginLeft: '10px'
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    className="next-button"
                    onClick={handleTrainClick}
                    disabled={isSubmitting}
                    style={{
                      width: '123px',
                      height: '53px',
                      background: '#0A80A0',
                      borderRadius: '5px',
                      padding: '10px 20px',
                      fontSize: '20px',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      color: '#FFFFFF',
                      marginLeft: '10px'
                    }}
                  >
                    Train
                  </button>
                )}
              </div>
              <input type="file" id="file-upload" className="hidden" onChange={handleFileUpload} multiple />
            </>
          ) : showProjectView ? (
            <ProjectView projectId={viewProjectId} onBack={() => setShowProjectView(false)} />
          ) : (
            <div className="project-details">
              
              <div className="search-bar">
    <div className="project-list-header">
        <h2>Projects List</h2>
        <p>Here is a list of projects that you have created.</p>
    </div>
    <div className="filter-sort">
        {allowedEmails.includes(userEmail) && (
            <div  
                className="add-projects" 
                onClick={() => setShowCreateProject(true)}
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    backgroundColor: '#d3d3d3',  // Light grey background
                    color: '#000',  // Black text color
                    padding: '8px 12px',  // Padding inside the button
                    borderRadius: '4px',  // Rounded corners
                    cursor: 'pointer',  // Pointer cursor on hover
                    fontSize: '14px',  // Text size
                    fontWeight: '500',  // Text weight
                }}
            >
                <img 
                    src={PlusIcon} 
                    alt="+" 
                    className="files-icon" 
                    style={{ 
                        width: '20px', 
                        marginRight: '10px',
                    }} 
                />
                Add Projects
            </div>
        )}
    </div>
</div>

              <div className="project-cards">
                {fetchError ? (
                  <div className="error">{fetchError}</div>
                ) : (
                  allProjects.slice(currentIndex, currentIndex + projectsPerPage).map((project, index) => (
                    <div key={index} className="project-card">
                      {/* <div className="project-category-box" style={{ backgroundColor: getRandomColor() }}>
                        <div className="project-category">Category {index + 1}</div>
                      </div> */}
                      <div className="project-title">{project.ProjectName}</div>
                      <div className="project-description">{project.Description}</div>
                      <div className="project-files-box">
                        {allowedEmails.includes(userEmail) ? (
                          <div className="project-files" onClick={() => handleFilesClick(project.IndexId)}>
                            <img src={filesIcon} alt="Files" className="files-icon" style={{width: '20px', marginRight: '10px'}} /> {/* This line adds the icon */}
                            {project.fileCount} Files
                          </div>
                        ) : (
                          <div className="project-files">
                            <img src={filesIcon} alt="Files" className="files-icon" style={{width: '21px', marginRight: '10px'}} /> {/* This line adds the icon */}
                            {project.fileCount} Files
                          </div>
                        )}
                      </div>
                      <div className="chat-icon" onClick={() => handleChatClick(project.IndexId)}>
                        <img src={chatIcon} alt="Chat" style={{width: '21px', height:'21px', marginLeft: '8px'}} className="chat-iconn-img" />
                      </div>
                      <div className="menu-icon" onClick={() => handleMenuToggle(index)}>
                        <i className="fa fa-ellipsis-v"></i>
                      </div>
                      {showMenuIndex === index && (
                        <div className="menu-dropdown">
                          {allowedEmails.includes(userEmail) ? (
                            <div className="menu-item" onClick={() => handleViewClick(project.IndexId)}>View</div>
                          ) : (
                            <div className="menu-item disabled">View</div>
                          )}
                          <div className="menu-item" onClick={handleDeleteClick}>Delete</div>
                        </div>
                      )}
                    </div>
                  ))
                )}
              </div>
              <div className="pagination">
                {Array.from({ length: Math.ceil(allProjects.length / projectsPerPage) }, (_, i) => (
                  <button
                    key={i}
                    className={`pagination-button ${currentIndex / projectsPerPage === i ? 'active' : ''}`}
                    onClick={() => handlePageClick(i)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {showFilePopup && (
        <FileListPopup projectId={popupProjectId} onClose={() => setShowFilePopup(false)} />
      )}
    </div>
  );
}

export default Home;

