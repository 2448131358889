import React, { useState } from "react";
import { authProvider, YOUR_SHAREPOINT_SITE_ID } from '../../../auth/authProvider';
import { Select, Space } from 'antd';
import ErrorComponent from "../../../Helper/Components/ErrorComponent";

const FileUploader = ({ account, onChange }) => {
    console.log("onChange", onChange)
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
 
    const fetchSharePointFiles = async () => {

        try {
            const accessToken = await authProvider.acquireTokenPopup({
                scopes: [ "user.read"],
                // account: account,
            });
    
            const response =  await fetch(`https://graph.microsoft.com/v1.0/sites/${YOUR_SHAREPOINT_SITE_ID}/drive/root/children`, {
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setFiles(data.value);
            }
            else{
                setError(response);
            }
        }
        catch(err){
            console.log(err);
            setError(err);
        }
      
    };
 
    const uploadToAzureBlob = async (selectedId) => {
        const file = files.filter((item)=> item.id === selectedId);
        onChange(file);
    };

    if(error) {
        return <ErrorComponent/>
    }
 
    return (
        <>
        <button style ={{ position:"relative", top:"60%", left:"10%"}}onClick={fetchSharePointFiles}>Fetch Files from SharePoint</button>
        <Space
        size ="large"
        direction="vertical" 
        style={
        { 
        width: '160%',
        position: "absolute",
        top: "130%",
        right: "103%"
        }
        
    }
    >        
    
        <Select 
            // status="error"
             placeholder={"Share Point to Project  "}
             onChange={(item)=>uploadToAzureBlob(item)}
            style={
                { width: '90%',
                height: "50px"
             }
            }
            options={files?.map((item)=> ({label: item.name, value: item.id}))}
            />
        </Space>
        </>
    );
};
 
export default FileUploader;
 