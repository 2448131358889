import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AppLandingPage from "./pages/App/App";
import ChatPage from "./pages/Chat/ChatPage";
import Chat from "./pages/Chat/newchat";
import Accessdenied from "./pages/AccessDenied/accessdenied";
import Comingsoon from "./pages/ComingSoon/comingsoon";
import LinkedInCallback from "./pages/LinkedIn/LinkedInCallback";
import Home from "./pages/Home/Home";
import UnderMaintenance from './pages/Maintenance/UnderMaintenance';
import PrivateRoute from './PrivateRoute'; // Import the PrivateRoute component
import Projects from "./components/SharePoint/Projects/Projects";
import Timeout from "./Helper/Components/TimeOut";


const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLandingPage />
  },
  {
    path: "/callback",
    element: <LinkedInCallback />
  },
  {
    path: "/accessdenied",
    element: <Accessdenied />
  },
  {
    path: "/comingsoon",
    element: <Comingsoon />
  },
  {
    path: "/newchat",
    element: <Chat />,
  },
  {
    path: "/ChatPage",
    element: <PrivateRoute element={ChatPage} /> // Protect the ChatPage route
  }
  ,
  {
    path: "/project",
    element: <PrivateRoute element={Projects} /> // Protect the sharepoint route
  },
  {
    path: "/home",
    element: <PrivateRoute element={Home} /> // Protect the Home route
  },
  {
    path: "/apps",
    element: <UnderMaintenance />,
  },
  {
    path: "/app-library",
    element: <UnderMaintenance />,
  }
  ,
  {
    path: "/project",
    element: <PrivateRoute element={Projects} /> // Protect the sharepoint route
  },
  {
    path: "/activity",
    element: <UnderMaintenance />,
  },
  {
    path: "/settings",
    element: <UnderMaintenance />,
  },
  {
    path: "/help",
    element: <UnderMaintenance />,
  },
  {
    path: "/error",
    element: <Timeout />,
  },
]);

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  return (
    <RouterProvider router={router} />
  );
}
