import React from 'react';
import { Button, Typography, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ErrorComponent = () => {
    const navigate = useNavigate();

    const handleRefresh = () => {
        window.location.reload();
    };

    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Oops, something went wrong.
            </Typography>
            <Typography variant="body1" paragraph>
                Please either refresh the page or return home and try again.
            </Typography>
            <Box mt={4}>
                <Button 
                  sx ={{
                      backgroundColor:"black",
                      color:"white",
                      '&:hover': {
                          backgroundColor: 'black', // Keeps the same color on hover
                      },
                      '&:focus': {
                           backgroundColor: 'black', // Keeps the same color on focus
                       },
                      '&:active': {
                          backgroundColor: 'black', // Keeps the same color when active
                      }
                   }}
                variant="contained" 
                onClick={handleRefresh}
                style={{ marginRight: '10px' }}>
                    Refresh Page
                </Button>
                <Button 
                  sx={{
                    backgroundColor:"black"
                  }}
                variant="contained" 
                 onClick={handleGoHome}>
                    Go Home
                </Button>
            </Box>
        </Container>
    );
};

export default ErrorComponent;
