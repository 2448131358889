import React, {useState, useEffect, useCallback} from "react"
import LoginHeader from "../../LoginHeader"
import { BlobServiceClient } from '@azure/storage-blob';
import { Select, Space, Row, Col, Button } from 'antd';
import './Project.css';
import { useNavigate } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import Loader from "../../../Loader";
import FileUploader from "./FileUploader";
import ErrorComponent from "../../../Helper/Components/ErrorComponent";

const Projects = () => {
  const [allProjects, setAllProjects] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();
  const [IndexId, setIndexId] = useState(null);
  const connectionString = process.env.REACT_APP_CONNECTION_STRING;
  const [success, setSuccess] = useState(true);

  const [loading, setLoading] = useState(false); // New state for loader
  const [fetchError, setFetchError] = useState(null);
  const [sharePointFile, setsharePointFile] = useState([]);
  const [error, setError] = useState(null);

  const handleSharePointFiles = useCallback((item)=>{
    setsharePointFile(item);
  });

  
  const fetchFileCountFromContainer = async (indexId) => {
    try {
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(indexId);

      let fileCount = 0;
      for await (const response of containerClient.listBlobsFlat()) {
        fileCount++;
      }

      return fileCount;
    } catch (error) {
      console.error('Error fetching file count from Azure Blob Storage:', error);
      setError(error)
      return 0; // Return 0 if there's an error
    }
  };

  const fetchAllProjects = async () => {
    try {
      const response = await fetch('https://acumantaiapi.azure-api.net/codecraftapi/get-projects', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const projects = await response.json();
        const projectsWithFileCount = await Promise.all(
          projects.results.map(async (project) => {
            const fileCount = await fetchFileCountFromContainer(project.IndexId);
            return { ...project, fileCount };
          })
        );
        setAllProjects(projectsWithFileCount);
        console.log("Fetched projects:", projectsWithFileCount);
      } else {
        const errorData = await response.json();
        console.error("Error fetching projects:", response.statusText, errorData);
        setFetchError('Error fetching projects');
        setError(errorData)
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setFetchError('Error fetching projects');
      setError(error);
    }
    
  };
  const handleUpload = async() => {
    console.log("upload called", IndexId);
    setIsSubmitting(true);
    setLoading(true); // Show loader

    try {
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(IndexId);
      await containerClient.createIfNotExists();
      const spFile = sharePointFile[0];
      const blockBlobClient = containerClient.getBlockBlobClient(spFile.name);
      const fileResponse = await fetch(spFile["@microsoft.graph.downloadUrl"]);
      const blob = await fileResponse.blob();
      const response = await blockBlobClient.uploadBrowserData(blob);
      console.log("Container created:", response);

     const {_response} = response;
      if (response.ok) {
        setIsSubmitting(false)
        setSuccess(false) 
      }
      else if (_response) {
        if(_response.status === 201){
          setIsSubmitting(false)
          setSuccess(false) 
        } 
      }
       else {
        const errorData = await response.json();
        console.error("Error starting training:", response.statusText, errorData);
        setError(errorData)
      }

    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:', error);
      setError(error);
    }

    setIsSubmitting(false);
    setLoading(false); // Hide loader
  }

  const handleTrainClick = async () => {
    console.log("handleTrainClick called", IndexId);
    setLoading(true); // Show loader

    try {

        setIsSubmitting(true)
        setSuccess(false)
        console.log("Training started successfully");
        

        // Additional API call to create-docs-metadata
        const metadataPromises = Array.from(allProjects).map(file => {
          const fileName = file.ProjectName;
          const fileExtension = ""
          const metadata = {
            ProjectId: IndexId,
            DocumentName: fileName,
            DocumentType: fileExtension,
            DocumentLocation: "share point",
            IsActive: true,
            CreatedBy: 'Admin'
          };

          return fetch('https://acumantaiapi.azure-api.net/codecraftapi/create-docs-metadata', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(metadata)
          });
        });

        await Promise.all(metadataPromises);
        console.log("Metadata created successfully");

        navigate(`/project?indexId=${IndexId}`);
     
    } catch (error) {
      console.error('Error uploading file to Azure Blob Storage:', error);
      setError(error)
    }

    setIsSubmitting(false);
    setLoading(false); // Hide loader
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  if(error) {
   return <ErrorComponent/>
  }



  return ( <div className="ecobot-main">
    <LoginHeader heading="" />
    {loading && <Loader />} {/* Show loader when loading */}
    <div className={`project-main-container ${loading ? 'blur' : ''}`}>
        <div className="project-content">
        <Row gutter={[16, 16]} >
        <Col span={14} marginLeft ={16}>
    <Space
    size ="large"
    direction="vertical" 
    style={
        { 
        width: '100%',
        position: "relative",
        // top: "10%",
        left: "10%",
        // border: "1px solid red"
        }
    }>
    <h6 style={{fontWeight:"bold", marginLeft:"5px", paddingTop:"20px", color:"#000"}}>SharePoint</h6>
    <Select 
    // status="error"
    placeholder={"Select Project"}
    onSelect={setIndexId}
    style={{ width: '80%', height: "50px" }}
    options={allProjects.map((item)=> ({label: item.ProjectName, value: item.IndexId}))}
    />
    
  </Space>
        </Col>
        <Col>
        <FileUploader onChange ={handleSharePointFiles}/>  
        </Col>
        <Col>
        <Button 
        // disabled={isSubmitting}
        onClick={handleUpload}
        size="large"
        icon={<UploadOutlined />}
        style={{
          width: '123px',
          height: '53px',
          position: "relative",
          top: "170%",
          left: "20%",
          background:"#000",
        }}    
        >
            Upload
         </Button>
        </Col>
        <Col>
        
        <button
                    type="button"
                    className="next-button"
                    onClick={handleTrainClick}
                    disabled={success}
                    style={{
                      width: '123px',
                      height: '53px',
                      background: '#0A80A0',
                      borderRadius: '6px',
                      padding: '10px 20px',
                      fontSize: '20px',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s ease',
                      color: '#FFFFFF',
                      position:"absolute",
                      top: "170%",
                      float:"left",
                      left :"50%",
                    }}
                  >
                    Train
                  </button>
        </Col>
        </Row>
        </div>  
        </div>
        </div>)
}

export default Projects;
