import "./App.css";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../../auth/authProvider';

function AppLandingPage() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // The hardcoded project information for "hr-policy"
  const hardcodedProject = {
    IndexId: 'hr-policy',
    Name: 'HR Policy'
  };

  return (
    <div id="wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 dark-bg-ai">
            <div className="green-lg">
              <img src="assets/img/aculogo.png" alt="" className="green-logo" />
            </div>
            <div className="aichat-img">
              <img className="landing-img" src="./assets/img/aichat.png" alt="AI Chat" />
            </div>
          </div>
          <div className="col-md-4 white-bg-login">
            <div className="login-box">
              <h1 className="ecobot-h1">Step into Acumant AI Hub</h1>
              <p className="ecobot-text">
                Embark on your journey through Acumant AI hub
              </p>
              <h2 className="ecobot-login-text">Login to Navigate</h2>

              {/* The login button will always render, but state changes are managed after AzureAD response */}
              <AzureAD provider={authProvider}>
                {({
                  login,
                  logout,
                  authenticationState,
                  error,
                  accountInfo,
                }) => {
                  // Render the login button immediately, while managing state updates
                  if (authenticationState === AuthenticationState.InProgress) {
                    setIsLoading(true);
                  }

                  if (authenticationState === AuthenticationState.Authenticated) {
                    const userEmail = accountInfo.account.userName;
                    const allowedEmails = [
                      'swapnil.agrawal@acumant.com',
                      'manju.siddoutam@acumant.com',
                      'naga@acumant.com',
                      'sudiendra.siddautam@acumant.com',
                      'deepak.gupta@acumant.com',
                      'gaurav.dutta@acumant.com',
                      'manish@acumant.com'
                    ];
                    localStorage.setItem('name', accountInfo.account.name);
                    localStorage.setItem('email', userEmail);

                    if (allowedEmails.includes(userEmail)) {
                      navigate("/Home");
                    } else {
                      // Hardcoded navigation to the "hr-policy" project
                      navigate(`/ChatPage?indexId=${hardcodedProject.IndexId}`);
                    }
                  }

                  return (
                    <button className="btn google__btn" onClick={login} disabled={isLoading}>
                      <img src="assets/microsoft_icon.png" alt="Microsoft Icon" />
                      {isLoading ? "Loading..." : "Sign in with Microsoft"}
                    </button>
                  );
                }}
              </AzureAD>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppLandingPage;