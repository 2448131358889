import React from 'react';
import './UnderMaintenance.css';
import settingsIcon from '../../Grey Icons/Settings.png'; // Assuming you have a settings icon image

const UnderMaintenance = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-card">
        <img src={settingsIcon} alt="Settings Icon" className="settings-icon" />
        <h1>Page Under Maintenance</h1>
        <p>We're currently working on this page. Please check back later.</p>
        <button onClick={() => window.location.href = '/'}>Go Back to Home</button>
      </div>
    </div>
  );
};

export default UnderMaintenance;
