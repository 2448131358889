import { MsalAuthProvider, LoginType } from 'react-aad-msal';
 
export const YOUR_SHAREPOINT_SITE_ID = "9f2b0edd-b5e4-4715-bc56-877b879b6c51"
// Msal Configurations
const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '9572bb5f-c2c2-4dda-b08a-8addc00b3051',
    // redirectUri: 'https://acumant-ai.azurewebsites.net/callback',
    // postLogoutRedirectUri:'https://acumant-ai.azurewebsites.net/'

    redirectUri: 'https://tools.acumant.com/callback',
    postLogoutRedirectUri:'https://tools.acumant.com/'

    // redirectUri: 'http://localhost:3005/callback',
    // postLogoutRedirectUri: "http://localhost:3005/"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};
 
// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}
 
// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)