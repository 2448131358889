import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import './ProjectView.css';
import deleteIcon from '../../assets/Grey/delete.png';
import ErrorComponent from '../../Helper/Components/ErrorComponent';

const ProjectView = ({ projectId, onBack }) => {
  const [projectDetails, setProjectDetails] = useState(null);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const connectionString = process.env.REACT_APP_CONNECTION_STRING;
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProjectDetailsAndFiles = async () => {
      try {
        console.log('Fetching project details and files for projectId:', projectId);
        // Fetch project details from API
        const response = await fetch(`https://acumantaiapi.azure-api.net/codecraftapi/get-projects`);
        if (response.ok) {
          const allProjects = await response.json();
          const projectDetails = allProjects.results.find(project => project.IndexId === projectId);
          setProjectDetails(projectDetails);
          console.log('Project details set:', projectDetails);

          await fetchFilesFromContainer(projectDetails.IndexId);
        } else {
          console.error('Error fetching project details:', response.statusText);
          setError(response)
        }
      } catch (error) {
        console.error('Error fetching project details and files:', error);
        setError(error)
      }
    };

    fetchProjectDetailsAndFiles();
  }, [projectId]);

  const fetchFilesFromContainer = async (indexId) => {
    try {
      console.log('Fetching files from container with indexId:', indexId);
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(indexId);

      let blobNames = [];
      let iter = containerClient.listBlobsFlat().byPage({ maxPageSize: 20 });

      for await (const response of iter) {
        console.log('Current page response:', response);
        const currentBlobNames = response.segment.blobItems.map(blob => blob.name);
        console.log('Extracted file names from current page:', currentBlobNames);
        blobNames = [...blobNames, ...currentBlobNames];
      }

      console.log('All extracted file names:', blobNames);
      setFiles(blobNames);
    } catch (error) {
      console.error('Error fetching files from Azure Blob Storage:', error);
      setError(error)
    }
  };

  const handleFileUpload = (event) => {
    setSelectedFiles(event.target.files);
  };

  const uploadFiles = async () => {
    if (selectedFiles.length === 0) return;

    setUploading(true);
    try {
      console.log('Uploading files to container with indexId:', projectDetails.IndexId);
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(projectDetails.IndexId);

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const blockBlobClient = containerClient.getBlockBlobClient(file.name);
        await blockBlobClient.uploadData(file);
        console.log('File uploaded:', file.name);
      }

      // Refresh the file list after upload
      await fetchFilesFromContainer(projectDetails.IndexId);

      // Trigger Pinecone API after files are uploaded
      await triggerPineconeTraining();

    } catch (error) {
      console.error('Error uploading files to Azure Blob Storage:', error);
      setError(error)
    } finally {
      setUploading(false);
    }
  };

  const triggerPineconeTraining = async () => {
    try {
      console.log("Triggering Pinecone training for container with indexId:", projectDetails.IndexId);
      const response = await fetch(`https://acumantaiapi.azure-api.net/codecraftapi/pinecone/train-pinecone-from-blob-storage-for-project?container_name=${projectDetails.IndexId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        console.log("Training started successfully");
        const responseData = await response.json();
        console.log("Response data:", responseData);

        // Additional API call to create-docs-metadata
        const metadataPromises = Array.from(selectedFiles).map(file => {
          const fileName = file.name;
          const fileExtension = fileName.split('.').pop();
          const metadata = {
            ProjectId: projectDetails.ProjectId,
            DocumentName: fileName,
            DocumentType: fileExtension,
            DocumentLocation: 'Project View', // Adjust based on context
            IsActive: true,
            CreatedBy: 'Admin'
          };

          return fetch('https://acumantaiapi.azure-api.net/codecraftapi/create-docs-metadata', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(metadata)
          });
        });

        await Promise.all(metadataPromises);
        console.log("Metadata created successfully");

        setUploadStatus('success');
      } else {
        const errorData = await response.json();
        console.error("Error starting training:", response.statusText, errorData);
        setUploadStatus('failure');
        setError(errorData)
      }
    } catch (error) {
      console.error('Error triggering Pinecone training:', error);
      setUploadStatus('failure');
      setError(error)
    }
  };

  if (error) {
    return <ErrorComponent/>
  }

  const deleteFile = async (fileName) => {
    try {
      console.log('Deleting file from container with indexId:', projectDetails.IndexId);
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(projectDetails.IndexId);
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      await blockBlobClient.delete();
      console.log('File deleted:', fileName);

      // Refresh the file list after deletion
      await fetchFilesFromContainer(projectDetails.IndexId);
    } catch (error) {
      console.error('Error deleting file from Azure Blob Storage:', error);
      setError(error)
    }
  };

  return (
    <div className="pv-container">
      <div className="pv-header-content">
        <h1>Project View</h1>
        <p>Here you can view and make changes in a project</p>
      </div>
      <div className="pv-content-container">
        <div className="pv-content">
          {projectDetails ? (
            <>
              <div className="pv-box">
                <h2>{projectDetails.ProjectName}</h2>
                <p>{projectDetails.Description}</p>
              </div>
              <div className="pv-upload-section">
                <input type="file" multiple onChange={handleFileUpload} />
                <button onClick={uploadFiles} disabled={uploading}>
                  {uploading ? 'Uploading...' : 'Upload Files'}
                </button>
              </div>
              <table className="pv-table">
                <thead>
                  <tr>
                    <th>File Name / ID</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {files.length > 0 ? (
                    files.map((fileName, index) => (
                      <tr key={index}>
                        <td>{fileName}</td>
                        <td>
                          <img src={deleteIcon} alt="Delete" className="pv-icon" onClick={() => deleteFile(fileName)} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No files found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <button className="back-buttonn" onClick={onBack}>Back</button>
            </>
          ) : (
            <p>Loading project details...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectView;