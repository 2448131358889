import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginHeader from "../../components/LoginHeader";
//import Navigation from './Navigation';
import "./chat.css";
import "./chatPage.css";
import SendIcon from "@mui/icons-material/Send";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBroom } from "@fortawesome/free-solid-svg-icons";
import copysvg from "../../assets/img/Copy.svg";
import editPen from "../../assets/img/Pencil_design.svg";
import downloadDesign from "../../assets/img/download_design.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
// import chatIcon from "./assets/img/ChatIcon.png";
import StopCircleIcon from '@mui/icons-material/StopCircle';
 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';
import ErrorComponent from "../../Helper/Components/ErrorComponent";
import PostWithTimeout from "../../Helper/PostWithTimeout"; 
import Timeout from "../../Helper/Components/TimeOut";

const PINECONE_QUERY_URL = "https://acumantaiapi.azure-api.net/codecraftapi/pinecone/query-text-from-pinecone";
const email = "gaurav.dutta%40acumant.com";

const ChatPage = () => {
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showGeneralQuestions, setShowGeneralQuestions] = useState(true);
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const chatBottom = useRef(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [typingInfo, setTypingInfo] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [citationContent, setCitationContent] = useState(null);
  const [citationHeading, setCitationHeading] = useState(null);
  const [botTyping, setBotTyping] = useState(false);
  const [tabValue, setTabValue] = useState("chat");
  const [summaryContent, setSummaryContent] = useState(null);
  const [files, setFiles] = useState(new FormData());
  const [fileName, setFileName] = useState('');
  const [questions, setQuestions] = useState([
    "What are the certification and wellness amounts for India and international locations?",
    "What expenses can be reimbursed under the wellness policy?",
    "Can you describe the maternity leave policy in India?",
    "What is my leave eligibility?"
  ]);

  const [timedOut, setTimedOut] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const intervalRef = useRef(null);
  const cancelTokenRef =  useRef(null);

  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);  // New state to manage admin status

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const indexName = queryParams.get("indexId") || "hr-policy";

  // Admin emails
  const adminEmails = [
    'swapnil.agrawal@acumant.com',
    'manju.siddoutam@acumant.com',
    'naga@acumant.com',
    'sudiendra.siddautam@acumant.com',
    'deepak.gupta@acumant.com',
    'gaurav.dutta@acumant.com',
    'manish@acumant.com'
  ];

  // Check if the user is an admin
  useEffect(() => {
    const userEmail = localStorage.getItem('email');
    if (adminEmails.includes(userEmail)) {
      setIsAdmin(true);  // Set admin status based on email
    }
  }, []);

  const generalQuestions =(id) => {
    let response = [];
    switch(id) {
      case 'hr-policy':
        response =[
          "What are the certification and wellness amounts for India and international locations?",
          "What expenses can be reimbursed under the wellness policy?",
          "Can you describe the maternity leave policy in India?",
          "What is my leave eligibility?"
        ]
        break;
      case 'pbi-reports':
        response= [
          "I want to build a report using allocationid, intensity. Are there any existing reports with these fields?",
          "I want to build a report on FuelConsumption, Can you check if there are current reports on FuelConsumption and help me with next steps to build new report on it?",
          "I want to analyze common causes of deadlocks in the system. Can you guide how can I start with?",
          "I want to get the status of long running queries. Can I get any lead to start on this?"
        ]
        break;
      case 'dynamics-fdds':
        response= [
          "What steps need to be taken during the release management process for modified functionality in the application?",
          "How will vendor invoices for contractors be processed in D365FO?",
          "What criteria must be met for transactions to be included in the queries for creating purchase orders from ProjEmplTrans and ProjCostTrans?",
          "How will the modifications made in the new functionality impact future upgrades and what scale should be used to evaluate this impact?"
        ]
        break;
      default:
        response =[
          "What are the certification and wellness amounts for India and international locations?",
          "What expenses can be reimbursed under the wellness policy?",
          "Can you describe the maternity leave policy in India?",
          "What is my leave eligibility?"
        ]
    }
    setQuestions(response);
    return response;
  }

  const fetchAllProjects = async () => {
    try {
      const response = await fetch('https://acumantaiapi.azure-api.net/codecraftapi/get-projects', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const projects = await response.json();
        setAllProjects(projects.results);
      } else {
        console.error("Error fetching projects:", response.statusText);
        setError(response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError(error);
    }
  };


  useEffect(() => {
    fetchAllProjects();
  }, []);

  const handleProjectChange = (e) => {
    const projectId = e.target.value;
    generalQuestions(projectId)
    setSelectedProject(projectId);
    clearChat();
    navigate(`/ChatPage?indexId=${projectId}`);
  };

  const handleGeneralQuestionClick = (question) => {
    setMessage(question);
    setShowGeneralQuestions(false);
    sendMessage(question);
  };
 
 
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };
 
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
 
  const scrollToBottom = () => {
    if (chatBottom.current) {
      chatBottom.current.scrollTop = chatBottom.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (typingInfo) {
        startBotResponseTyping(typingInfo.botResponse, typingInfo.charIndex, typingInfo.startTime);
      }
    } else {
      if (chatStarted && messages.length > 0 && messages[messages.length - 1].sender === "bot") {
        setTypingInfo({
          botResponse: messages[messages.length - 1].text,
          charIndex: typingInfo ? typingInfo.charIndex : 0,
          startTime: new Date(),
        });
      }
    }
  };
 
  const getTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  };
 
  const startBotResponseTyping = (botResponse, charIndex, startTime) => {
    if (botResponse.text.includes("Generating Answer...")) {
      console.log(botResponse);
      setBotTyping(false);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory.slice(0, -1),
        botResponse,
      ]);
      return;
    }
    const typingSpeed = 15;
    intervalRef.current =  setInterval(() => {
      const currentTime = new Date();
      const elapsed = currentTime - startTime;
      const newCharIndex = Math.floor(elapsed / typingSpeed);
      const newText = botResponse.text.substring(0, newCharIndex);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory.slice(0, -1),
        { text: newText, sender: "bot", time: getTime() },
      ]);
      if (newCharIndex >= botResponse.text.length) {
        clearInterval(intervalRef.current);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, -1),
          botResponse,
        ]);
        setBotTyping(false);
      }
    }, typingSpeed);
  };

  const sendMessage = async (text = message) => {
    const fileCount = files.getAll('file').length;
    if(files && fileCount>0){
      try {
        setFileName("");
        const userMessage = { text, sender: "user" };
        setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
        setMessage("");
        setShowGeneralQuestions(false);
        setShowWelcomeMessage(false);
        setTimedOut(false);
  
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { text: "Generating Answer...", sender: "bot", time: getTime() },
        ]);
        setBotTyping(true);
        console.log(files);
        cancelTokenRef.current = axios.CancelToken.source();
        const PINECONE_QUERY_URL = "https://acumantaiapi.azure-api.net/codecraftapi/analyze-document";
        const question = text;
        PostWithTimeout(`${PINECONE_QUERY_URL}?question=${question}`, files,  {
          cancelToken: cancelTokenRef.current.token,
      })
        .then(response => {
          console.log(response.data);
          const botResponse = {
            text: response.data.result,
            sender: "bot",
            citation: "",
          };
          startBotResponseTyping(botResponse, 0, new Date());
          
        })
        .catch(error => {
          if (error.message === 'Request timed out') {
            setTimedOut(true);
            setDialogOpen(true);
          }
          else {
            console.error('Error:', error)
            setError(error.message)
          }
        });
           
        setFiles(new FormData());
      } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
        } else {
            console.error('Error during POST request:', error);
        }
    } finally {
        setBotTyping(false);
    }
    }
    else{
      if (!chatStarted) {
        setChatStarted(true);
      }
      const userMessage = { text, sender: "user" };
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      setMessage("");
      setShowGeneralQuestions(false);
      setShowWelcomeMessage(false);

      try {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { text: "Generating Answer...", sender: "bot", time: getTime() },
        ]);
        setBotTyping(true);

        const response = await fetch(
          `${PINECONE_QUERY_URL}?index_name=${indexName}&query_text=${encodeURIComponent(text)}&email=${email}`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );

        if (response.ok) {
          const responseJson = await response.json();
          const botResponse = {
            text: responseJson.results.answer,
            sender: "bot",
            citation: responseJson.results.sources,
          };
          startBotResponseTyping(botResponse, 0, new Date());
        } else {
          console.error(`Error: ${response.status} - ${response.statusText}`);
          setError(error.message)
        }
      } catch (error) {
        console.error("Error fetching bot response:", error);
        setError(error.message)
      }
    }
  };
 
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
      }
  };
 
  const handleSendButtonClick = () => {
    sendMessage();
  };

  const handleStopResponse = () => {
    setBotTyping(false);
    setChatStarted(false)
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
  }
    if (cancelTokenRef.current) {
      clearInterval(cancelTokenRef.current);
      cancelTokenRef.current.cancel('Operation canceled by the user.');
  }
};
  
  
  
  const handleDialogClose = () => {
    setDialogOpen(false);
    window.location.reload();
  };

  const handleRetry = () => {
    setDialogOpen(false);
  };
 
  const clearChat = () => {
    setChatHistory([]);
    setChatStarted(false);
    setSidebarOpen(false);
    setShowGeneralQuestions(true);
    setShowWelcomeMessage(true);
  };
 
  const handleCopy = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedMessageIndex(index);
        setTimeout(() => {
          setCopiedMessageIndex(null);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };
 
  const handleDownload = (text) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "response.txt";
    document.body.appendChild(element);
    element.click();
  };
 
  const handleEdit = (text) => {
    setMessage(text);
    document.getElementById("messageInput").focus();
  };
 
  const handleHeadingClick = (heading, content) => {
    if (citationHeading !== heading) {
      setCitationHeading(heading);
      setCitationContent(content);
      setSidebarOpen(true);
      setExpanded("panel1");
    } else {
      setSidebarOpen(!sidebarOpen);
    }
  };
 
  const renderTextWithBoldAndLineBreaks = (text) => {
    if (!text) return null;
    const boldRegex = /\*\*(.*?)\*\*/g;
    return text.split("\n").map((line, lineIndex) => (
      <div key={lineIndex} className="message-line">
        {line.split(boldRegex).map((part, index) => {
          if (index % 2 === 1) {
            return <strong key={index}>{part}</strong>;
          } else {
            return part;
          }
        })}
      </div>
    ));
  };
 
 
  const uploadAttchement = async(event) => {
    const files = event.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
 
 
    setFiles(formData);
    console.log("formData:", formData);
 
    for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
    }
    console.log("uploadedFiles:", files);
    setFileName(files[0].name);
  }
 
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "summary") {
      fetchSummary();
    }
  };
 
  const fetchSummary = async () => {
    try {
      const response = await fetch(
        `${PINECONE_QUERY_URL}?index_name=${indexName}&query_text=${encodeURIComponent("Provide a summary of the project in 50 words")}&email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
 
      if (response.ok) {
        const responseJson = await response.json();
        setSummaryContent(responseJson.results.answer);
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
        setError({message: "Failed to call chat api"})
      }
    } catch (error) {
      console.error("Error fetching project summary:", error);
      setError(error.message)
    }
  };
 
  const configContainerStyle = {
    color: 'inherit',
    backgroundColor: 'inherit'
  };

  if(error) {
    return <ErrorComponent/>
  }
 
  return (
    <div className="ecobot-main">
      <LoginHeader heading="" />
      <div className="main-container">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between',background:'#f7f7f7' }}>
          <div
            style={{
              position: "relative",
              marginLeft: "100px", // Adjust this value to move the tabs to the right
              display: "flex",
              flexDirection: "row",
              fontFamily: "'Noto Sans', 'sans-serif'",
              zIndex: 1
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
    textColor="inherit"  // Inherit the color from the parent or set it manually in the sx prop
    indicatorColor="primary" // We will override this in sx
              aria-label="secondary tabs example"
    variant="fullWidth" // Ensure tabs take up full width within the container
              sx={{
                '& .MuiTab-root': {
                  color: '#000', // Set tab text color to black
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#000', // Set the underline indicator color to black
                },
              }}
            >
              <Tab value="chat" label="ChatBot"  disabled={botTyping}/>
              <Tab value="summary" label="Summary"  disabled={botTyping} />
    {/* <Tab
    disabled={botTyping}
      value="config"
      label="Configuration"
     
    /> */}
            </Tabs>
 
          </div>

 
<FormControl variant="standard" sx={{ minWidth: 240, marginRight: '100px',  marginTop: "10px" }}>
            <InputLabel id="project-select-label">Select a project</InputLabel>
            <Select
    sx ={{
      backgroundColor:"white"
    }}
              defaultValue={indexName}
              disabled={botTyping}
              labelId="project-select-label"
              id="project-select"
              value={indexName}
              onChange={handleProjectChange}
              label="Select a project"
            >
              <MenuItem value="hr-policy">HR Policy</MenuItem>
              {isAdmin && <MenuItem value="dynamics-fdds">Dynamics-FDDS</MenuItem>}
            </Select>
          </FormControl>

        </div>
        {tabValue === "chat" && (
          <div className={`chat-container ${sidebarOpen ? "sidebar-open" : "sidebar-close"}`}>
            <div className={`chat-window ${chatStarted ? "" : "chat-not-started"}`}>
              {!chatStarted && (
                <div className="project-dropdown-custom">
                  {showWelcomeMessage && (
                    <div style={{ paddingTop: '30px', fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center',fontFamily: "'Noto Sans', 'sans-serif'" }}>
                    Hello and welcome! <br></br>I'm your virtual assistant, here to help you with your queries.<br></br> How can I assist you today?
                    </div>
                  )}
                </div>
              )}
              {showGeneralQuestions && (
                <div className="general-questions" style={{ position: 'relative', width: '100%',paddingLeft:'15px',paddingRight:'55px',paddingTop: '12%' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {questions.map((question, index) => (
                        <div
                            key={index}
                            style={{
                              flex: '0 0 48%',
                              padding: '10px',
                              margin: '10px 0',
                              borderRadius: '8px',
                              border: '1px solid #ccc',
                              cursor: 'pointer',
                              transition: 'background-color 0.3s ease',
                              color: '#000',
                              fontSize: '14px',
                              fontFamily: "'Noto Sans', 'sans-serif'",
                              backgroundColor: 'white',
                              textAlign: 'center',
                          }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f7f7f7'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'white'}
                            onClick={() => handleGeneralQuestionClick(question)}
                            className=""
                        >
                            {question}
                        </div>
                    ))}
                </div>
        </div>

              )}
              <div className="chat-box-msg" ref={chatBottom}>
                {chatHistory.map((msg, index) => (
                  <div key={index} className={`message  ${msg.sender}`}>
                    {msg.sender === "user" && (
                      <>
                        <img src="assets/img/User.png" alt="User Profile" className="profile-icon user-icon" />
                        <span className="csrd-title">You</span>
                        <div className="message-text pd-l-40">
                          {msg.text.split("\n").map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                        <div className="action-icons pd-l-40">
                          <img src={copysvg} alt="Copy" className="action-icon" title="Copy" onClick={() => handleCopy(msg.text, index)} />
                          <img src={editPen} alt="Edit" className="action-icon" style={{ width: "21px", height: "-53px" }} title="Edit" onClick={() => handleEdit(msg.text)} />
                          {copiedMessageIndex === index && <span className="copied-message">Copied</span>}
                        </div>
                      </>
                    )}
                    {msg.sender === "bot" && (
                      <>
                        <img src="assets/img/favicon.png" alt="Bot" className=" profile-icon bot-icon" />
                        <span className="csrd-title">Acumant bot</span>
                        <div className="message-text pd-l-40">
                          {renderTextWithBoldAndLineBreaks(msg.text)}
                          <div className="row">
                            <div className="col-md-8">
                              {msg.citation && (
                                <Accordion
                                  expanded={expanded === "panel1"}
                                  onChange={() => setExpanded(expanded === "panel1" ? false : "panel1")}
                                  className="citation-acc"
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    className="accordion-summ"
                                  >
                                    <span className="citation-res">{msg.citation.length ?? 0} Resources</span>
                                  </AccordionSummary>
                                  <AccordionDetails className="accordion-history">
                                    {msg.citation &&
                                      msg.citation.map((citationItem, index) => (
                                        <div key={index} className="dummy-heading-container">
                                          <div className="pdf-heading-container">
                                            <p
                                              className="dummy-heading"
                                              onClick={() => handleHeadingClick(citationItem.document_id, citationItem.content)}
                                            >
                                              {citationItem.document_id}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </div>
                            <div className="col-md-4"></div>
                          </div>
                        </div>
                        <div className="row mt20 mb20">
                          <div className="col-md-6">
                            <div className="action-icons pd-l-40">
                              <img src={copysvg} alt="Copy" className="action-icon" title="Copy" onClick={() => handleCopy(msg.text, index)} />
                              {copiedMessageIndex === index && <span className="copied-message">Copied</span>}
                              <img src={downloadDesign} alt="Download" className="action-icon" style={{ width: "26px", height: "26px" }} title="Download" onClick={() => handleDownload(msg.text)} />
                            </div>
                          </div>
                          <div className="col-md-6 text-right pd-r50">
                            <span className="font-13">AI-generated content may be incorrect</span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <label htmlFor="file-upload" className="custom-file-upload">
                {fileName}
              </label>
              <div className="input-container" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <button
        disabled={botTyping}
        style={{
            padding: "0px",
            backgroundColor: "white",
            position: 'absolute',
            left: '10px',
            border: 'none',
            cursor: 'pointer'
        }}
        title="Attach File"
    >
      <input
        multiple
        onChange={uploadAttchement}
        type="file"
        id="file-upload"
        style={{
          display: 'none',
        }}
      />
      <label
        htmlFor="file-upload"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent:"center",
          cursor: 'pointer',
          padding: '10px',
          borderRadius:"100%",
          backgroundColor: 'black',
        }}
      >
        <FontAwesomeIcon
          icon={faPaperclip}
        />
      </label>
    </button>
   
    <input
        id="messageInput"
        type="text"
        value={message}
        onChange={handleMessageChange}
        onKeyPress={handleKeyPress}
        placeholder="Search anything..."
        className="input-box"
        disabled={botTyping}
        style={{ paddingLeft: '65px' }}
    />
    {
      botTyping ? 
      
      <button
      onClick={handleStopResponse}
    disabled={!botTyping}
    className="send-button"
    title="Stop"
    style={{ marginLeft: '10px' }}
    >
    <StopCircleIcon />
    </button> : 
      <button
      onClick={handleSendButtonClick}
      disabled={botTyping}
      className="send-button"
      title="Send"
      style={{ marginLeft: '10px' }}
  >
      <SendIcon />
  </button> 
    }   
    
   
    <button
        disabled={botTyping}
        style={{
            padding: "0px",
            backgroundColor: "white",
            border: 'none',
            cursor: 'pointer',
            marginLeft: '10px'
        }}
        title="New Chat"
    >
        <FontAwesomeIcon icon={faBroom} onClick={clearChat} className="clear-chat-icon" />
    </button>
</div>
 
            </div>
 
            <div className="chat-sidebar">
              <div className="sidebar-content">
                <button className="close-sidebar-button" onClick={handleCloseSidebar}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h3 className="citation-text">{citationHeading}</h3>
                {citationContent && citationContent.split("\n").map((line, index) => <div key={index}>{line}</div>)}
              </div>
            </div>
          </div>
        )}
  {tabValue === "summary" && (
  <div className="chat-container" style={{ marginTop: 'px' }}>
    <div className={`chat-window ${chatStarted ? "" : "chat-not-started"}`}>
      {!chatStarted && (
        <div className="project-dropdown-custom">
          {showWelcomeMessage && (
            <div style={{ paddingTop: '30px', fontSize: '1.2em', fontWeight: 'bold', textAlign: 'left' }}>
              Project Summary
            </div>
          )}
        </div>
      )}
      <div className="chat-box-msg">
        <div className={`message bot`}>
          <div className="message-text pd-l-40">
            {summaryContent && (
              <div>
                {summaryContent.split("\n").map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}
        {tabValue === "config" && (
          <div className="config-container" style={configContainerStyle}>
          </div>
        )}
      </div>
      <Timeout open={dialogOpen} onClose={handleDialogClose} onRetry={handleRetry} />
    </div>
  );
};

export default ChatPage;