import React from "react";
import clsx from "clsx";
import { useNavigate } from 'react-router-dom';
// import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "../../components/SideBar.js";
import LoginHeader from "../../components/LoginHeader.js";
import SideNavigation from "../../components/SideNavigation.js";
import containerTheme from '../../theme/containerTheme.js'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { authProvider } from '../../auth/authProvider.js';
import { AzureAD, AuthenticationState } from 'react-aad-msal';


// const useStyles = makeStyles(theme => (containerTheme(theme)));

export default function Home() {
  // const className = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  // const fixedHeightPaper = clsx(className.paper, className.fixedHeight);
  const handleLogin = () => {
    console.log('in')
    if(email === "demo@ecoprism.com" ) {
      localStorage.setItem('userName', "demo@ecoprism.com");
      localStorage.setItem('username', "demo@ecoprism.com");  
      navigate('/home')
    }
    else if(email === "danishagro@test.com" ) {
      localStorage.setItem('userName', "danishagro@test.com"); 
      localStorage.setItem('username', "danishagro@test.com");  
      navigate('/home')
    } 
    else if(email === "admin@ecoprism.com" ) {
      localStorage.setItem('userName', "admin@ecoprism.com");  
      navigate('/home')
    } 
    else if(email === "ecodev@acumant.com" ) {
      localStorage.setItem('userName', "ecodev@acumant.com");  
      navigate('/home')
    }
    
    else if(email === "ecodemo@ecoprism.com" ) {
      localStorage.setItem('userName', "ecodemo@ecoprism.com");  
      navigate('/home')
    }
   
    else if(email === "ecopowerbi@ecoprism.com" ) {
      localStorage.setItem('userName', "ecopowerbi@ecoprism.com");  
      navigate('/home')
    }
    
    else {
        setError('Email is incorrect')
    }
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }
  const handlePass = (e) => {
    setPassword(e.target.value)
  }

    
  const login = () => {
    navigate('/login')
  }    
 
  return (
    <div id="wrapper " className="access-bg">
         {/* ======= Header ======= */}
         <header id="header" className="fixed-top dark-bg ">
              <div className="container">
                {/* <h1 className="logo me-auto"><a href="index.html">Arsha</a></h1> */}
                {/* Uncomment below if you prefer to use an image logo */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <a href="/" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid logo-img"/></a>                
                  </div>
                  <div className='col-lg-6'>                    
                    {/* <a href="#login" className="btn-login" onClick={login}>Login/Signup</a>              */}
                  </div>
                </div>
              </div>
            </header>{/* End Header */}
            {/* ======= Hero Section ======= */}
     <div className="login__wrapper">
      {/* <LoginHeader heading=""/> */}
        <section className="login__section">
          <div className="row">
          <div class="center-screen">
        <div className="access-denied">
              <img className="denied-img" src="./assets/img/protection.png" alt=""/>
              <h1>Access Denied !</h1>
              <p className="access-txt">You are not authorized to access this website.</p>
               </div>
              </div>
            </div>
        </section>
      </div>
    </div>
  
  );
}
function App() {
  return (
    <div className="App">
      <AzureAD provider={authProvider}>
        <span>Only authenticated users can see me.</span>
      </AzureAD>
      
    </div>
  );
}
