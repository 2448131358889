import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from '../auth/authProvider';
import { Link } from 'react-router-dom';

import HamburgerMenu from "./HamburgerMenu";

export default function Header(props) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const name = localStorage.getItem('name');
  const email = localStorage.getItem('email'); // Get the user's email from localStorage
  const openProfile = Boolean(anchorEl);
  const [time, setTime] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const useCurrentCallback = (callback) => {
    const reference = useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };

  const currentCallback = useCurrentCallback(() => {
    const date = new Date();
    setTime(date.toISOString());
  });

  useEffect(() => {
    const handle = setInterval(currentCallback, 100);
    return () => clearInterval(handle);
  }, [currentCallback]);

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  // List of allowed admin email addresses
  const allowedEmails = [
    'swapnil.agrawal@acumant.com',
    'manju.siddoutam@acumant.com',
    'naga@acumant.com',
    'sudiendra.siddautam@acumant.com',
    'deepak.gupta@acumant.com',
    'gaurav.dutta@acumant.com',
    'manish@acumant.com'

  ];

  // Check if the logged-in user is an admin
  const isAdmin = allowedEmails.includes(email);

  return (
    <React.Fragment>
      {location.pathname !== "/login" && (
        <nav className="navbar navbar-expand-lg navbar-light custome__header">
          <div className="container-fluid ai-header">
            {/* Render HamburgerMenu only if the user is an admin */}
            {isAdmin && <HamburgerMenu />}
            
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
         
            <img
              src="assets/img/aculogo.png"
              alt=""
              className="green-logo"
            />
            <div className="float-right">
              <ul className="navbar-nav mb-2 mb-lg-0 user-box ">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    onClick={handleDropdownClick}
                    aria-expanded={isOpen}
                  >
                    <span className="username-text">{name}</span>
                  </a>
                </li>
                |
                <li>
                  <AzureAD provider={authProvider} forceLogin={true}>
                    {({ login, logout, authenticationState, error, accountInfo }) => {
                      switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                          return (
                            <Link className="username-text" onClick={logout}>
                              <FontAwesomeIcon icon={faSignOutAlt} className="text-muted fs-16 align-middle me-1" />
                              <span className="align-middle" data-key="t-logout" style={{ color: "#000" }}>Logout</span>
                            </Link>
                          );
                        default:
                          return null;
                      }
                    }}
                  </AzureAD>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

      {location.pathname === "/login" && (
        <nav className="navbar navbar-expand-lg custome__header">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src="assets/img/logo.png" alt="" className="img-logo" />
            </a>
          </div>
        </nav>
      )}
    </React.Fragment>
  );
}
