import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import './FileListPopup.css';
import deleteIcon from '../../assets/Grey/delete.png';

const FileListPopup = ({ projectId, onClose }) => {
  const [files, setFiles] = useState([]);
  const connectionString = process.env.REACT_APP_CONNECTION_STRING;

  useEffect(() => {
    const fetchFilesFromContainer = async (indexId) => {
      try {
        const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
        const containerClient = blobServiceClient.getContainerClient(indexId);

        let blobNames = [];
        for await (const blob of containerClient.listBlobsFlat()) {
          blobNames.push(blob.name);
        }

        setFiles(blobNames);
      } catch (error) {
        console.error('Error fetching files from Azure Blob Storage:', error);
      }
    };

    fetchFilesFromContainer(projectId);
  }, [projectId]);

  const deleteFile = async (fileName) => {
    try {
      const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
      const containerClient = blobServiceClient.getContainerClient(projectId);
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);

      await blockBlobClient.delete();
      setFiles(files.filter(file => file !== fileName));
    } catch (error) {
      console.error('Error deleting file from Azure Blob Storage:', error);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-content">
        <div className="popup-header">
          <h2>Project Files</h2>
          <button onClick={onClose} className="close-buttonn">×</button>
        </div>
        <div className="popup-body">
          {files.length > 0 ? (
            <ul className="file-list">
              {files.map((file, index) => (
                <li key={index} className="file-item">
                  <span>{file}</span>
                  <img src={deleteIcon} alt="Delete" onClick={() => deleteFile(file)} className="delete-icon" />
                </li>
              ))}
            </ul>
          ) : (
            <p>No files found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileListPopup;
