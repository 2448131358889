import React, { useState,useRef,useEffect } from "react";
import "./chat.css"; // Import the CSS file
import "./chatPage.css"; // Import the CSS file
import LoginHeader from "../../components/LoginHeader.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import dislikesvg from "../../assets/img/Dislike.svg";
import copysvg from "../../assets/img/Copy.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SendIcon from "@mui/icons-material/Send"; // Import Send icon
import { useNavigate } from 'react-router-dom';
import { faBroom } from "@fortawesome/free-solid-svg-icons";
import ErrorComponent from "../../Helper/Components/ErrorComponent.jsx";
const Chat = () => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);
 
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [messages, setMessages] = useState([]);
  const username = localStorage.getItem("userName");
  const picture = localStorage.getItem("picture");
  const chatBottom = useRef(null);
  const [citation, setCitation] = useState([]);
  const [typingInfo, setTypingInfo] = useState(null);
  const [chatStarted, setChatStarted] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [relatedContent, setRelatedContent] = useState(null);
  const [relatedHeading, setRelatedHeading] = useState(null);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  let navigate = useNavigate();
  useEffect(() => {
    const codeDataJSON = localStorage.getItem("linkedinCode");
 
    if (codeDataJSON) {
      const codeData = JSON.parse(codeDataJSON);
      // Check if the code is still valid (not expired)
      if (codeData.expirationTime > new Date().getTime()) {
        navigate("/Chatpage");
        return codeData.code; // Return the code if it's still valid
      } else {
        // If the code is expired, remove it from local storage
        localStorage.removeItem("linkedinCode");
        navigate("/");
      }
    } else {
      navigate("/");
    }
  }, []);
  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      // Resume typing if there is pending typing info
      if (typingInfo) {
        startBotResponseTyping(
          typingInfo.botResponse,
          typingInfo.charIndex,
          typingInfo.startTime
        );
      }
    } else {
      // Save typing info when tab is not visible
      if (
        chatStarted &&
        messages.length > 0 &&
        messages[messages.length - 1].sender === "bot"
      ) {
        setTypingInfo({
          botResponse: messages[messages.length - 1].text,
          charIndex: typingInfo ? typingInfo.charIndex : 0,
          startTime: new Date(),
        });
      }
    }
  };

  const getTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  const startBotResponseTyping = (botResponse, charIndex, startTime) => {
    const typingSpeed = 15; // Adjust the delay as needed for typing speed
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const elapsed = currentTime - startTime;
      const newCharIndex = Math.floor(elapsed / typingSpeed);
      const newText = botResponse.text.substring(0, newCharIndex);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory.slice(0, -1),
        { text: newText, sender: "bot", time: getTime() },
      ]);
      if (newCharIndex >= botResponse.text.length) {
        clearInterval(intervalId);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory.slice(0, -1),
          botResponse,
        ]);
      }
    }, typingSpeed);
  };

  const sendMessage = async () => {
    if (message.trim() !== "") {
      if (!chatStarted) {
        setChatStarted(true);
      }
      // Store user's message

      const userMessage = { text: message, sender: "user" };

      // Update chat history with user's message
      setChatHistory((prevChatHistory) => [...prevChatHistory, userMessage]);
      setMessage(""); // Clear input field

      try {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { text: "Generating Answer...", sender: "bot", time: getTime() },
        ]);
        const response = await fetch(
          "https://ecoprismapi.azurewebsites.net/sendMessage",
          {
            method: "GET",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              username: username,
              text: message,
            },
          }
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          const botResponse = {
            text: responseJson.results.answer,
            sender: "bot",
            citation: responseJson.results.sources, // Include citation information
          };

          //setChatHistory(prevChatHistory => [...prevChatHistory, botResponse]);
          startBotResponseTyping(botResponse, 0, new Date());
        }
      } catch (error) {
        console.error("Error fetching bot response:", error);
        setError(error);
      }
      // Update chat history with bot's response
    }
  };
  const scrollToBottom = () => {
    chatBottom.current.scrollIntoView({ behavior: "smooth" });
  };
  const clearChat = () => {
    setChatHistory([]);
  };
  const handleLike = () => {
    // Handle like action
  };

  const handleDislike = () => {
    // Handle dislike action
  };

 

  const handleCopy = (message) => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };
  const handleEdit = (index) => {
    // Handle edit action
    console.log("Edit message:", messages[index]);
  };
 
  const handleHeadingClick = (heading, content) => {
    setSidebarOpen(!sidebarOpen);
    setRelatedHeading(heading);
    setRelatedContent(content);
    setExpanded("panel1"); // Assuming 'panel1' corresponds to the Citation accordion
  };
  
  if (error) {
    return <ErrorComponent />
  }

  return (
    <div className="ecobot-main">
      <LoginHeader heading="" />
      <div className="main-container">
        <div className={`chat-container ${sidebarOpen ? "sidebar-open" : ""}`}>
          <div className="chat-window">
            <div className="chat-box-msg">
              {/* Display chat messages */}
              {chatHistory.map((msg, index) => (
                <div key={index} className={`message  ${msg.sender}`}>
                  {msg.sender === "user" && (
                    <>
                      <img
                        src={picture}
                        alt="User Profile"
                        className="profile-icon user-icon"
                      />
                      <span className="csrd-title">You</span>
                      <div className="message-text pd-l-40">
                        {msg.text.split("\n").map((line, index) => (
                          <div key={index}>{line}</div>
                        ))}
                      </div>
                    </>
                  )}
                  {msg.sender === "bot" && (
                    <>
                      <img
                        src="assets/img/favicon.png" // Replace with path to bot icon
                        alt="Bot"
                        className="icon profile-icon bot-icon"
                      />
                      <span className="csrd-title">CSRD ecobot</span>
                      <div className="message-text pd-l-40">
                        <div className="typewriter">
                          {msg.text.split("\n").map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            {msg.citation && (
                              <Accordion className="citation-acc">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2-content"
                                  id="panel2-header"
                                  className="accordion-summ"
                                >
                                  <span>
                                    {msg.citation.length ?? 0} Resources
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails className="accordion-history">
                                  {msg.citation &&
                                    msg.citation.map((citationItem, index) => (
                                      <div
                                        key={index}
                                        className="dummy-heading-container"
                                      >
                                        <div className="pdf-heading-container">
                                          <p
                                            className="dummy-heading"
                                            onClick={() =>
                                              handleHeadingClick(
                                                citationItem.metadata
                                                  .document_id,
                                                citationItem.page_content
                                              )
                                            }
                                          >
                                            {citationItem.metadata.document_id}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </div>
                          <div className="col-md-4"></div>
                        </div>
                      </div>
                      <div className="row mt20">
                        <div className="col-md-6">
                          <div className="action-icons pd-l-40">
                            <img
                              src={dislikesvg}
                              alt="Dislike"
                              className="action-icon"
                              onClick={handleDislike}
                            />
                            <img
                              src={copysvg}
                              alt="Copy"
                              className="action-icon"
                              onClick={() => handleCopy(message.text)}
                            />
                            {copied && (
                              <span className="copied-message">Copied</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <span className="font-13">
                            AI-generated content may be incorrect
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
              <div ref={chatBottom}></div>
              {!chatStarted && (
                    <div className="chat-image">
                      <img
                        src=""
                        alt="Chat Image"
                        className="chat-start"
                      />
                    </div>
                  )}
            </div>
            <div className="input-container">
              <input
                type="text"
                value={message}
                onChange={handleMessageChange}
                placeholder="Ask me anything..."
              />
              <button onClick={sendMessage} className="send-button">
                <SendIcon />
              </button>
              {/* Replace Clear Chat button with ClearIcon */}
              <FontAwesomeIcon
                icon={faBroom}
                onClick={clearChat}
                className="clear-chat-icon"
              />
            </div>
          </div>

          <div className="chat-sidebar">
            {/* Content for the sidebar */}
            <div className="sidebar-content">
              <button
                className="close-sidebar-button"
                onClick={handleCloseSidebar}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <h3 className="citation-text">{relatedHeading}</h3>
              {relatedContent &&
                relatedContent
                  .split("\n")
                  .map((line, index) => <div key={index}>{line}</div>)}
              {/* <p>{relatedContent.map((line, index) => (<div key={index}>{line}</div>))}</p> */}
            </div>
          </div>
        </div>

        {/* <button className="toggle-sidebar-button" onClick={toggleSidebar}>
          Toggle Sidebar
        </button> */}
      </div>
    </div>
  );
};

export default Chat;
